interface PlatformWarmupDataService {
  get: <T>(key: string) => T | null;
  set: <T>(key: string, value: T) => void;
}

export class WarmupDataService implements PlatformWarmupDataService {
  private callMap: Record<string, boolean> = {};

  constructor(private warmupDataService: PlatformWarmupDataService) {}

  public get<T>(key: string) {
    if (this.callMap[key]) {
      return null;
    }

    const warmupData = this.warmupDataService.get<T>(key);
    this.callMap[key] = true;
    return warmupData;
  }

  public set<T>(key: string, value: T) {
    return this.warmupDataService.set(key, value);
  }
}
