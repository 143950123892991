import {
  MultiStateBoxService as IMultiStateBoxService,
  W,
} from '../../../types';

enum AppState {
  Main = 'main',
  Error = 'error',
}

export class MultiStateBoxService implements IMultiStateBoxService {
  constructor(private readonly $w: W) {}

  async showMainAppState() {
    await this.getAppStatesMSB()?.changeState?.(AppState.Main);
  }

  async showErrorAppState() {
    await this.getAppStatesMSB()?.changeState?.(AppState.Error);
  }

  async showSlotStateById(stateId: string) {
    await this.getSlotsStatesMSB()?.changeState?.(stateId);
  }

  getCurrentStateId() {
    const msb = this.getSlotsStatesMSB();

    return msb?.currentState?.id ?? '';
  }

  private getAppStatesMSB(): $w.MultiStateBox | null {
    return this.$w('#appStates');
  }

  private getSlotsStatesMSB(): $w.MultiStateBox | null {
    return this.$w('#appsContainer');
  }
}
