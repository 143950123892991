import model from './model';

export default model.createController(
  ({ $bind, $widget, flowAPI, controllerConfig }) => {
    return {
      pageReady: async () => {
        const changeState = (stateId: string) => {
          return controllerConfig.$w('#appsContainer').changeState('b1');
        };

        $widget.fireEvent('widgetLoaded', {});
        changeState('b1');
      },
      exports: {},
    };
  },
);
